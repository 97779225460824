<script>
import { onMounted, onUpdated, computed, reactive, ref } from "vue";
import { http } from "@/http";
import { useRoute,useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isSuccess = route.query.status
    
    
    const handleClickPath = () => {
      window.location.href = route.query.url
    }

    const handleClickNativePath = (path) => {
      window.location.href = path
    }

    onMounted(() => {
      document.title = '懒猪快送授权'
    })
    
    return {
      isSuccess,
      handleClickPath,
      handleClickNativePath
    };
  },
};
</script>
<template>
  <div style="text-align: center; padding: 1.8rem 1rem">
    <template v-if="isSuccess">
      <p>
        <img
          style="width: 2rem; height: 2rem; margin: 0.3rem 0"
          src="../../assets/images/icon-success.png"
        />
      </p>
      <p style="font-weight: bold">授权成功</p>
    </template>
    <template v-else>
      <p>
        <img
          style="width: 2rem; height: 2rem; margin: 0.3rem 0"
          src="@/assets/images/icon-fail.png"
        />
      </p>
      <p>授权失败</p>
    </template>

    <div class="btn-ct">
      <p v-if="!isSuccess">
        <van-button
          block
          type="primary"
          @click="handleClickPath"
          >重新授权</van-button
        >
      </p>
      <p v-else>
        <van-button
          block
          type="primary"
          @click="handleClickNativePath('lpmerchant://route/store')"
          >门店管理</van-button
        >
      </p>
      <p>
        <van-button
          block
          type="primary"
          @click="handleClickNativePath('lpmerchant://route/pop/root')"
          >返回首页</van-button
        >
      </p>
    </div>
  </div>
</template>
<style scoped>
.btn-ct {
  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 0;
}
.btn-ct p {
  margin-bottom: 0.4rem;
}
div.error-text p {
  text-align: left;
}
</style>